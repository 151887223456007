import React, { Fragment, useState, useEffect } from "react";
import "./styles.css";
import { connect } from "react-redux";
import _ from "lodash";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import axios from "axios";
import moment from "moment";
import { useNavigate } from "react-router";


const NotificationsHelper = ({ authData }) => {
    const navigate = useNavigate();
    const [ state, setState ] = useState({
        notifications: [
            {
              title: 'New Message',
              date: '3d',
              description: 'You have a new message from John Doe. The message is about the upcoming project and includes important details.',
            },
            {
              title: 'Meeting Reminder',
              date: '1w',
              description: 'Reminder: Team meeting on Monday at 10 AM. The agenda includes project updates, sprint planning, and a discussion on upcoming features.',
            },
            {
              title: 'Friend Request',
              date: '2w',
              description: 'You have received a friend request from Jane Smith. Jane is a colleague from the design team, and she is interested in connecting with you to discuss potential collaborations.',
            },
            {
              title: 'Payment Received',
              date: '5d',
              description: 'Payment of $100 received from Client XYZ for the recent freelance work completed. Please check your account for confirmation.',
            },
            {
              title: 'Upcoming Event',
              date: '10d',
              description: 'Don\'t forget about the networking event scheduled for next Friday. It will be a great opportunity to meet industry professionals and expand your connections.',
            },
            {
              title: 'System Update',
              date: '2w',
              description: 'A system update is scheduled for tomorrow at 2 AM. During this time, there might be temporary service interruptions. We apologize for any inconvenience caused.',
            },
            {
              title: 'Product Launch',
              date: '3w',
              description: 'Exciting news! Our new product is launching next month. Be prepared for an internal training session scheduled for employees on the latest features and functionalities.',
            },
            {
              title: 'Feedback Request',
              date: '4w',
              description: 'You have been selected to provide feedback on the new website design. Your insights are valuable in shaping the user experience. Please participate in the survey at your earliest convenience.',
            }
        ],
        user: null
    })


    useEffect(() => {
        const configuration = {
            params: {
                uniqueId: authData.uniqueId,
                accountType: "standard"
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/gather/general/info/user`, configuration).then((res) => {
            if (res.data.message === "Successfully ran logic!") {
                console.log("res.data", res.data);

                const { user } = res.data;
                
                setState(prevState => ({ ...prevState, user, notifications: typeof user.notifications !== "undefined" && user.notifications.length > 0 ? user.notifications : [] }))
            } else {
                console.log("err");
            }
        })
    }, []);

    console.log("state.notifications", state.notifications);

    const handleNotificationClick = (type, listingID) => {
        switch (type) {
            case "outbid":
                navigate(`/lot_page/${listingID}`);
                break;
            default:
                break;
        }
    };

    const calculateType = (type) => {
        switch (type) {
            case "outbid":
                return "You were OUTBID! Click this notification to take action..."
                break;
            default:
                break;
        }
    }

    const handleCheckboxChange = (value, string) => {
        switch (string) {
            case "enroll":
                const configurationOne = {
                    uniqueId: authData.uniqueId,
                    checked: true
                }
        
                axios.post(`${process.env.REACT_APP_BASE_URL}/enroll/notifications`, configurationOne).then((res) => {
                    if (res.data.message === "Successfully ran logic!") {
                        console.log("res.data", res.data);
        
                        const { user } = res.data;
        
                        setState(prevState => ({ ...prevState, user }))
                    } else {
                        console.log("err");
                    }
                })
                break;
            case "unenroll":
                const configurationTwo = {
                    uniqueId: authData.uniqueId,
                    checked: false
                }
        
                axios.post(`${process.env.REACT_APP_BASE_URL}/enroll/notifications`, configurationTwo).then((res) => {
                    if (res.data.message === "Successfully ran logic!") {
                        console.log("res.data", res.data);
        
                        const { user } = res.data;
        
                        setState(prevState => ({ ...prevState, user }))
                    } else {
                        console.log("err");
                    }
                })
                break;
            default:
                break;
        }
    };

    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="min-height">
                <div className="container">
                    <div style={{ marginTop: 50, marginBottom: 50 }} className="row">
                        <div className="col-md-8 col-lg-8 col-sm-12 col-xs-12">
                            <div style={{ minWidth: "100%" }} className="box shadow-sm rounded bg-white mb-3">
                                <div className="box-title border-bottom p-3">
                                    <h6 className="m-0">Notifications</h6>
                                </div>
                                <div className="box-body p-0">
                                    {typeof state.notifications !== "undefined" && state.notifications.length > 0 ? state.notifications.sort((a, b) => {
                                        // Convert timestamps to Date objects if they're not already in a comparable format
                                        let dateA = new Date(a.timestamp);
                                        let dateB = new Date(b.timestamp);
                                        // Compare the dates
                                        return dateB - dateA;
                                    }).map((notification, index) => {
                                        return (
                                            <Fragment key={index}>
                                                <div style={{ marginTop: 22.25 }} className="p-3 d-flex align-items-center border-bottom osahan-post-header">
                                                    <div id="specialspanmobile" className="dropdown-list-image mr-3 d-flex align-items-center bg-danger justify-content-center rounded-circle text-white">
                                                        DRM
                                                    </div>
                                                    <div style={{ minWidth: "72.5%" }} id="marginleftmobile" className="font-weight-bold mr-3">
                                                        <div className="text-truncate" style={{ fontWeight: "bold", marginLeft: 22.25, marginRight: 50 }}>{calculateType(notification.item_name)}</div>
                                                        <div className="small" style={{ marginLeft: 22.25 }}>
                                                            {notification.action_prompt}
                                                        </div>
                                                    </div>
                                                    <hr />
                                                    <span style={{ float: "right" }} className="ml-auto mb-auto">
                                                        <div style={{ float: "right" }} className="btn-group">
                                                            <button
                                                                type="button"
                                                                className="btn btn-light btn-sm rounded"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                                style={{ marginLeft: -22.25 }}
                                                                id="btntext"
                                                                onClick={() => handleNotificationClick(notification.item_name, notification.auction_link)}
                                                            >
                                                                Manage
                                                            </button>
                                                        </div>
                                                        <br />
                                                        <div className="text-right text-muted pt-1" style={{ float: "right", marginRight: 23.25 }} >{moment(notification.timestamp).fromNow(true)}</div>
                                                    </span>
                                                </div>
                                            </Fragment>
                                        );
                                    }) : <div style={{ padding: 22.25 }}>
                                        <h4 className="text-left">You do not currently have any pending notifications...</h4>    
                                    </div>}
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-lg-4 col-sm-12 col-xs-12">
                            {/* Notifications preferences card*/}
                            <div className="card">
                                <div className="card-header">Notification Preferences</div>
                                <div className="card-body">
                                <form>
                                    {/* Form Group (notification preference checkboxes)*/}
                                    <div className="form-check mb-2">
                                    <input
                                        className="form-check-input" // enrolledEmailNotifications
                                        id="checkAutoGroup"
                                        checked={typeof state.user !== 'undefined' && state.user !== null && state.user.enrolledEmailNotifications === true ? true : false}
                                        onChange={(e) => {
                                            console.log("value", e.target.value);

                                            handleCheckboxChange(e.target.value, "enroll")
                                        }}
                                        type="checkbox"
                                    />
                                    <label className="form-check-label" htmlFor="checkAutoGroup">
                                        Get updates via email when new notifications arrive
                                    </label>
                                    </div>
                                    <div className="form-check mb-3">
                                    <input
                                        className="form-check-input"
                                        id="checkAutoProduct"
                                        type="checkbox"
                                        checked={typeof state.user !== 'undefined' && state.user !== null && state.user.enrolledEmailNotifications === false ? true : false}
                                        onChange={(e) => {
                                            console.log("value", e.target.value);

                                            handleCheckboxChange(e.target.value, "unenroll")
                                        }}
                                    />
                                    <label className="form-check-label" htmlFor="checkAutoProduct">
                                        Do NOT subscribe via email for new notifications, only view notifications via this view
                                    </label>
                                    </div>
                                    {/* Submit button*/}
                                    {/* <button style={{ color: "#fff" }} className="btn btn-danger-soft">
                                        Apply notification settings
                                    </button> */}
                                </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: typeof state.auth.data !== "undefined" && _.has(state.auth.data, "uniqueId") ? state.auth.data : null
    }
};
  
export default connect(mapStateToProps, {  })(NotificationsHelper);