import React, { Fragment, useEffect } from "react";
import "./styles.css";
import { Link } from "react-router-dom";
import { GoogleMap, useJsApiLoader, Marker } from '@react-google-maps/api';

const containerStyle = { width: '225px', height: '150px', zIndex: 0 };

const center = { lat: 41.876610, lng: -87.624573 };

const FooterUniversalHelper = () => {

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API
    })
    
    const [map, setMap] = React.useState(null)
    
    const onLoad = React.useCallback(function callback(map) {
        // This is just an example of getting and using the map instance!!! don't just blindly copy!
        // const bounds = new window.google.maps.LatLngBounds(center);
        console.log("window.google.maps.", window.google.maps);
        // map.fitBounds(bounds);
        setMap(map)
    }, [])
    
    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])
    
    return (
        <Fragment>
            <footer id="footer">
                <div className="logo-container">
                    <Link to="/">
                        <img src={require("../../../assets/CVA Logo Bold Text.jpg")} alt="CVA logo" className="logo" />
                    </Link>
                    <div className="foot-links">
                        <li>
                        <Link to="/terms_and_conditions">Terms of Use</Link>
                        </li>
                        <li>
                        <Link to="/cookie_policy">Cookie Policy</Link>
                        </li>
                        <li>
                        <Link to="/privacy_policy">Privacy Policy</Link>
                        </li>
                    </div>
                    </div>
                    <div className="footer-right">
                    <div className="socials">
                        <a target="_blank" href="https://www.instagram.com/chicagoviolinauction?igsh=YjhuNDM5NjRocGk1&utm_source=qr">
                        <img src={require("../../../assets/Instagram Logo.png")} alt="Instagram Icon" />
                        </a>
                        <a target="_blank" href="https://www.facebook.com/chicago.violin.auction?mibextid=uzlsIk">
                        <img src={require("../../../assets/Facebook Logo 1.png")} alt="Facebook Icon" />
                        </a>
                        <a target="_blank" href="https://x.com/chicagoauction/">
                        <img src={require("../../../assets/X Logo Twitter.png")} alt="Twitter X Icon" />
                        </a>
                    </div>
                    <div className="map" style={{ paddingTop: 15, paddingBottom: 15 }}>
                        {isLoaded ? (
                            <GoogleMap
                                mapContainerStyle={containerStyle}
                                center={center}
                                zoom={10}
                                onLoad={onLoad}
                                onUnmount={onUnmount}
                            >
                                <Marker position={center} />
                            </GoogleMap>
                        ) : <></>}
                        {/* Embedded Google Map (Replace src with the actual URL) */}
                        {/* <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d380514.4935884925!2d-88.06160667491979!3d41.833261703220344!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x880e2c3cd0f4cbed%3A0xafe0a6ad09c0c000!2sChicago%2C%20IL%2C%20USA!5e0!3m2!1sen!2sru!4v1701624407721!5m2!1sen!2sru"
                        width={200}
                        height={130}
                        style={{ border: 0, padding: 20 }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        /> */}
                    </div>
                </div>
            </footer>
        </Fragment>
    );
} 

export default FooterUniversalHelper;