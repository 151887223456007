import React, { useEffect } from "react";
import ContactHelperComponent from "../../components/contact/index";

const ContactPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return <ContactHelperComponent props={props} />
};

export default ContactPage;