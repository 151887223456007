import React, { Fragment, useEffect } from "react";
import ForgotPasswordHelper from "../../../components/auth/forgotPassword/forgot";

const ForgotPasswordPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return (
        <Fragment>
            <ForgotPasswordHelper props={props} />
        </Fragment>
    );
};

export default ForgotPasswordPage;