import React, { useEffect } from "react";
import LandingPageHelper from "../../components/landing/index";

const LandingPageMainPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return <LandingPageHelper />
};

export default LandingPageMainPage;