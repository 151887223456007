import React, { Fragment } from "react";
import LoginHelperComponent from "../../../components/auth/login/index";

const LoginPage = (props) => {
    return (
        <Fragment>
            <LoginHelperComponent props={props} />
        </Fragment>
    );
};

export default LoginPage;