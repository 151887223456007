import React, { Fragment } from "react";
import ShippingLabelsHelper from "../../../components/auction/shipping/labels";

const ShippingLabelsPage = (props) => {
    return (
        <Fragment>
            <ShippingLabelsHelper props={props} />
        </Fragment>
    );
} 
export default ShippingLabelsPage;