import React, { useEffect } from "react";
import AdminPanelHelper from "../../components/admin/index";

const AdminPanelPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return <AdminPanelHelper props={props} />
};

export default AdminPanelPage;