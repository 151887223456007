import React, { Fragment, useEffect } from "react";
import LotPageHelper from "../../components/lotpage/index";

const LotPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return (
        <Fragment>
            <LotPageHelper />
        </Fragment>
    );
};

export default LotPage;