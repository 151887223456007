import React, { Fragment, useEffect } from "react";
import NotificationsHelper from "../../components/notifications/index";

const NotificationsPage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0); //
      }, []);
    
    return (
        <Fragment>
            <NotificationsHelper props={props} />
        </Fragment>
    );
};

export default NotificationsPage;