import React, { Fragment, useEffect } from "react";
import RegisterHelperComponent from "../../../components/auth/signup/index";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const RegisterPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return (
        <Fragment>
            <Elements stripe={stripePromise}>
                <RegisterHelperComponent props={props} />
            </Elements>
        </Fragment>
    );
};

export default RegisterPage;