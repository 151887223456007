import React, { Fragment } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import {
    useWindowSize,
    useWindowWidth,
    useWindowHeight,
} from '@react-hook/window-size'
import Slider from "react-slick";
                             

const images = [
    {
        id: 1,
        image: require("../../assets/johnviolin.png")   
    },
    {
        id: 2,
        image: require("../../assets/johnviolin2.png")   
    },
    {
        id: 3,
        image: require("../../assets/johnviolin3.png")   
    },
    {
        id: 4,
        image: require("../../assets/johnviolin4.png")   
    },
    {
        id: 5,
        image: require("../../assets/johnviolin5.png")   
    } 
];

const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    autoplaySpeed: 2500,
    autoplay: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true
};     


const AboutUsHelper = (props) => {
    const [width, height] = useWindowSize()
    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="small-content" />
            <div className="row">
                <div className="row" style={{ padding: 22.25, paddingTop: 82.25 }}>
                    <section className="section gray-bg" id="about">
                        <h1>About us</h1>
                        <p><b>Welcome to The Chicago Violin Auction</b>. With a focus on the consignment and sale of violin family instruments and their respective bows, we are a pioneering startup-auction, based in one of the violin trades historic epicenters - downtown Chicago. The mission of The Chicago Violin Auction is to start an old-school, barebones style violin auction, modernized as an internet-based provider. <br/><br/>Currently, auction buyers and sellers in the U.S. have limited choices. Non-string-instrument-specific auction houses lack the expertise or credibility to generate high wholesale prices and fail to maintain post-sale anonymity and discretion on the Internet. String-instrument-specific auction houses are forced to reject many potential offerings due to excessive seller-side demand. At the same time, the string-instrument-specific auction houses' efforts to generate retail traffic make them a less desirable marketplace for trade buyers. Chicago Violin Auction LLC will fill the gap between retail and wholesale that has been widening as auction houses have increasingly catered to the retail trade. </p>
                        <hr />
                        <div className="about-container" style={{ marginTop: 40 }}>
                            <div className="about-content">
                                <div className="row">
                                    <div className="col-md-7 col-lg-7 col-sm-12">
                                        <div className="about-text">
                                            <h1>Welcome to the Chicago Violin Auction</h1>
                                            <p style={{ fontSize: 13.75 }}>
                                                Welcome to the inaugural sale of the Chicago Violin Auction. I am very pleased to welcome you to our project.
                                            </p>
                                            <p style={{ fontSize: 13.75 }}>
                                                The Chicago Violin Auction started as an idea to be a marketplace for trade buyers. Whereas violin-specific
                                                auction houses in the United States seemed inaccessible and associated with larger retail brands, we wanted
                                                to bring a no-frills auction to market. The divide between retail auction, pseudo-trade auction, and local
                                                auction seemed vast, evermore so with the closing of the musical instrument department at Skinner. Thus, the
                                                Chicago Violin Auction was conceived.
                                            </p>
                                            <p style={{ fontSize: 13.75 }}>
                                                We are happy to show you our first sale which ends <strong>Dec 8</strong>. The catalog is comprised of
                                                approximately 75 instruments and bows from the collection of a former Chicago violin shop which operated
                                                Downtown for many years. You can find a photo collage of the process to the right!
                                            </p>
                                            <p style={{ fontSize: 13.75 }}>
                                                <strong>P.S.</strong> Please join us for a Public Viewing, December 2-7. We will be open daily from
                                                12:00pm to 6:00pm CT in the Fine Arts Building (Studio 732), located at 410 S. Michigan Ave, Chicago, IL
                                                60605. I am looking forward to meeting you there!
                                            </p>
                                            <p className="signature">Sincerely,<br />John Ryan, Head Auctioneer</p>
                                            <hr />
                                            <img
                                                src={require("../../assets/selfie.png")}
                                                style={{ maxWidth: 625, maxHeight: 425 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-5 col-lg-5 col-sm-12">
                                        <div className="about-images">
                                            <Slider {...settings}>
                                                {images.map((item, index) => (
                                                    <img
                                                        key={index}
                                                        src={item.image}
                                                        style={{ maxWidth: 100, maxHeight: 100 }}
                                                        alt={`Violin Collection ${item.id}`}
                                                    />
                                                ))}
                                            </Slider>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>  
                    </section>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

export default AboutUsHelper;
