import React, { Fragment, useEffect, useState } from "react";
import "./styles.css";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim"; 
import { useNavigate } from "react-router-dom";

const RestrictedPage = (props) => {
    const [ init, setInit ] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        window.scrollTo(0, 0); //

        initParticlesEngine(async(engine) => {
            await loadSlim(engine);
        }).then(() => {
            setInit(true);
        });
    }, []);

    const particlesLoaded = (container) => {
        console.log(container);
    };

    return (
        <Fragment>
            <div className="min-height">
                <div id="particles-js">
                    {init ? <Particles
                        id="tsparticles"
                        particlesLoaded={particlesLoaded}
                        options={{
                            background: {
                                color: {
                                    value: "#0d47a1",
                                },
                            },
                            fpsLimit: 120,
                            interactivity: {
                                events: {
                                    onClick: {
                                        enable: true,
                                        mode: "push",
                                    },
                                    onHover: {
                                        enable: true,
                                        mode: "repulse",
                                    },
                                    resize: true,
                                },
                                modes: {
                                    push: {
                                        quantity: 4,
                                    },
                                    repulse: {
                                        distance: 200,
                                        duration: 0.4,
                                    },
                                },
                            },
                            particles: {
                                color: {
                                    value: "#ffffff",
                                },
                                links: {
                                    color: "#ffffff",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1,
                                },
                                move: {
                                    direction: "none",
                                    enable: true,
                                    outModes: {
                                        default: "bounce",
                                    },
                                    random: false,
                                    speed: 6,
                                    straight: false,
                                },
                                number: {
                                    density: {
                                        enable: true,
                                        area: 800,
                                    },
                                    value: 80,
                                },
                                opacity: {
                                    value: 0.5,
                                },
                                shape: {
                                    type: "circle",
                                },
                                size: {
                                    value: { min: 1, max: 5 },
                                },
                            },
                            detectRetina: true,
                        }}
                    /> : null}
                    <div className="page-404">
                        <div className="outer">
                        <div className="middle">
                            <div className="inner">
                            {/*BEGIN CONTENT*/}
                            <div className="inner-circle">
                                <i className="fa fa-cogs" />
                                <span>500</span>
                            </div>
                            <span className="inner-status">Opps! You don't have permission to view this page.</span>
                            <span className="inner-detail" style={{ color: "#fff" }}>
                                Unfortunately you cannot view this page with your account permissions/privileges. Please go back or click the button below to return to the homepage!
                            </span>
                            <button style={{ width: "100%" }} className="btn btn-primary" onClick={() => navigate("/")}>Return Home</button>
                            {/*END CONTENT*/}
                            </div>
                        </div>
                        </div>
                    </div>
                    </div>
            </div>
        </Fragment>
    );
};

export default RestrictedPage;