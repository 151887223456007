import allReducers from "../reducers/root.js";
import { createStore, applyMiddleware, compose } from "redux";
import { thunk } from "redux-thunk";
import { persistStore, persistReducer, createTransform } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import promiseMiddleware from 'redux-promise';
import { stringify, parse } from 'flatted';

const transformCircular = createTransform(
    (inboundState, key) => stringify(inboundState),
    (outboundState, key) => parse(outboundState),
)

const persistConfig = {
    key: 'primary',
    storage: storage,
    transforms: [transformCircular]
};

const pReducer = persistReducer(persistConfig, allReducers);

const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(pReducer, composeEnhancers(applyMiddleware(thunk, promiseMiddleware)));

export const persistor = persistStore(store);

store.subscribe(() => {
	console.log("Store is now: ", store.getState());
})