import React, { useEffect } from "react";
import AboutUsHelper from "../../components/about/index";

const AboutPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return <AboutUsHelper props={props} />
};

export default AboutPage;