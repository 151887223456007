import React, { Fragment } from "react";
import ConfirmEmailHelper from "../../../components/auth/email/confirm";

const ConfirmEmailPage = (props) => {
    return (
        <Fragment>
            <ConfirmEmailHelper props={props} />
        </Fragment>
    );
}

export default ConfirmEmailPage;