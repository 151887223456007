import React, { Fragment, useState } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Parallax, Background } from "react-parallax";
import image4 from "../../assets/images/axe.png"
import ImageUploading from 'react-images-uploading';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

const SellersHelper = (props) => {
    const [ activeTab, setActiveTab ] = useState("link1");

    const handleTabClick = (tabId, event) => {
        event.preventDefault();
        setActiveTab(tabId);
    }

    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="middle-content">
                <div className="small-card-container">
                <h2>Sellers</h2>
                {/* <p>Lorem ipsum dolor sit amet consectetur adiposcing elit</p> */}
                </div>
            </div>
            {/* Links Menu - add and rename as many as needed */}
            <div>
                <ul className="links-menu">
                    <li onClick={(event) => handleTabClick('link1', event)}>
                        <a className="hover" style={{ color: "#fff" }}>Consign with Us</a>
                    </li>
                    <li onClick={(event) => handleTabClick('link2', event)}>
                        <a className="hover" style={{ color: "#fff" }}>Services that We Provide</a>
                    </li>
                    <li onClick={(event) => handleTabClick('link3', event)}>
                        <a className="hover" style={{ color: "#fff" }}>Shipping & Photos</a>
                    </li>
                    <li onClick={(event) => handleTabClick('link4', event)}>
                        <a className="hover" style={{ color: "#fff" }}>Why Sell with Us?</a>
                    </li>
                </ul>
                <div className="contact-content">
                    <form id="link1" style={{ display: activeTab === 'link1' ? 'block' : 'none', minWidth: "96vw", margin: 12.25 }}>
                        <h2>Consign with Us</h2>
                        <hr style={{ width: "750px" }} />
                        <p style={{ display: "inline-block", textAlign: "left" }} id="about">Thank you for considering selling your bowed stringed instruments with us! We are thrilled to provide you with our service. Our team is dedicated to ensuring the best possible outcomes for your instruments and maximizing their market potential. To learn more about selling through The Chicago Violin Auction, you can browse the links above or navigate through our FAQ section below.

                        <br />
                        <br />
                        To consign with us online or through in-person consultation, please send us an inquiry through our <b>'Contact Us'</b> page, or <b>Email Us</b> at <a href="mailto:info@chicagoviolinauction.com">info@chicagoviolinauction.com</a>. Once we receive your inquiry, our specialist will reach out to you to discuss the next steps, including evaluation and potential auction dates.
                        <br />
                        <br />
                        </p>
                    </form>
                    <form id="link2" style={{ display: activeTab === 'link2' ? 'block' : 'none', minWidth: "96vw", margin: 12.25 }}>
                        <h2>Services that We Provide</h2>
                        <hr style={{ width: "750px" }} />
                        <p id="about" style={{ display: "block" }}>At The Chicago Violin Auction, we offer a comprehensive range of services to ensure the successful sale of your valuable instrument:
                        <br />
                        <br />
                        1. <b>Consultation and Services</b>: Our team conducts an obligation-free evaluation of your properties’ condition, provenance, and market demand to optimize its selling potential. Additionally, we guide you throughout the consignment process, including advice on estimates and reserve prices, to maximize auction results.
                        <br />
                        <br />
                        2. <b>Professional Photography</b>: We provide complimentary professional studio photography for all items consigned with us. This ensures that your instrument is presented in the best possible light, enhancing its appeal to potential buyers.
                        <br />
                        <br />
                        3. <b>Shipping Assistance</b>: To enhance convenience for our buyers, we offer in-house shipping services at competitive rates. This simplifies the transaction process and ensures seamless delivery of your property to its new owner.
                        <br />
                        <br />
                        4. <b>Marketing and Promotion</b>: We employ strategic marketing tactics to showcase your instrument to a wide audience of potential buyers. Through our website, industry connections, email campaigns, and social media promotion, we maximize exposure and drive interest in your consigned items.

                        </p>
                    </form>
                    <form id="link3" style={{ display: activeTab === 'link3' ? 'block' : 'none', minWidth: "96vw", margin: 12.25 }}>
                        <h2>Shipping and Photos</h2>
                        <hr style={{ width: "750px" }} />
                        <p id="about" style={{ display: "block" }}><b>Photos:</b> During the evaluation phase of the consignment process, to ensure that we can assess your violin accurately, please provide photographs that are clear, in focus, and taken in good lighting. Include multiple angles of the violin: a full front view, back view, side views, and close-ups of the scroll, labels, brand, and any unique features. Please be sure to include any documentation or relevant history regarding the provenance of the violin and expectations on potential value at auction. If we find your instrument suitable for our coming sale, we may ask for you to ship the instrument to our Chicago location for further evaluation. 
                        <br />
                        <br />
                        <b>Shipping</b>: Shipping a violin requires careful attention to packaging and handling to ensure it reaches its destination intact. Start by selecting a sturdy box that fits the violin or violin case, providing ample space for padding. Place the violin securely inside its case and cushion it with bubble wrap or foam padding to prevent movement during transit. Choose a reliable shipping carrier that offers tracking and insurance options (we recommend the UPS or Fedex). Pack the box with additional cushioning material, ensuring the violin case is centered and surrounded by padding on all sides. Seal the box securely with packing tape, reinforcing the corners and edges for added protection. If you have any further questions regarding the shipment of your property, feel free to contact us. We are here to help!
                        </p>
                    </form>
                    <form id="link4" style={{ display: activeTab === 'link4' ? 'block' : 'none', minWidth: "96vw", margin: 12.25 }}>
                        <h2>Why Sell with Us?</h2>
                        <hr style={{ width: "750px" }} />
                        <p id="about">Choosing the Chicago Violin Auction to sell your bowed stringed instruments such as violins, violas, cellos, and their bows, ensures that your prized possessions receive specialized exposure in a highly focused niche market. We are dedicated to connecting your instruments with buyers from around the world, fostering competitive bidding and ensuring the best possible sale outcomes. Our auction service stands out for offering some of the most competitive seller consignment fees in the industry, making it financially advantageous for you to consign with us. Despite these competitive rates, we do not compromise on the quality of our services. We uphold the highest standards of professionalism, integrity, and transparency throughout the consignment and auction process. By choosing to consign with us, you gain access to a global network of buyers who recognize and value your instruments.</p>
                        <br />
                        <p style={{ marginLeft: 20 }}>The Problem?</p>
                        <p>Currently, auction buyers and sellers in the U.S. have limited choices. Non-string-instrument-specific auction houses lack the expertise or credibility to generate high wholesale prices and fail to maintain post-sale anonymity and discretion on the Internet. String-instrument-specific auction houses are forced to reject many potential offerings due to excessive seller-side demand. At the same time, the string-instrument-specific auction houses' efforts to generate retail traffic make them a less desirable marketplace for trade buyers. Chicago Violin Auction LLC will fill the gap between retail and wholesale that has been widening as auction houses have increasingly catered to the retail trade.</p>
                        <p style={{ marginLeft: 20 }}>The Chicago Violin Auction</p>
                        <p>Choosing The Chicago Violin Auction to sell your bowed stringed instrument related property, ensures that your items receive specialized exposure in a niche market. We specialize in connecting your instruments with buyers from around the world, fostering competitive bidding and ensuring the best possible sale outcomes. Our auction service stands out for offering some of the most competitive seller consignment fees in the industry. Despite these competitive rates, we do not compromise on the quality of our services. We uphold the highest standards of professionalism, integrity, and transparency throughout the consignment, auction, and post-auction process. </p>
                    </form>
                </div>
            </div>
            <div className="middle-content-backgrounded">
                <div className="container" style={{ backgroundColor: "#fff" }}>
                    <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                        {/* <Markdown allowMultipleExpanded={true} remarkPlugins={[remarkGfm]}>{markdown}</Markdown> */}
                        <Accordion>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                        How do I consign my violin for the auction?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Reach out to us via <b>email</b> at <a href="mailto:info@chicagoviolinauction.com">info@chicagoviolinauction.com</a> or through our <b>'Contact Us'</b> page on our website. Please provide basic information about your violin, including any significant history or provenance. 
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    What types of instruments are accepted for consignment?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    At The Chicago Violin Auction, we accept a broad range of items for consignment, including, but not limited to, string instruments such as violins, violas, cellos, along with their respective bows. We also consign related musical items like books on stringed instruments, high-quality tonewoods for instrument making, and various accessories. If you are unsure about the suitability of your item for our auction, please contact us!
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    What is the seller's fee associated with consigning my violin?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    At Chicago Violin Auction, we offer one of the industry's most competitive seller's premiums, charging only <b>10%</b> on the final hammer price of your instrument.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Can I set a reserve price for my violin?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Yes, you can set a reserve price for your violin at the Chicago Violin Auction. During the consignment process, we work with you to determine a suitable reserve price based on the instrument's appraisal, market trends, and your personal expectations. 
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    When will I receive payment for my violin if it sells at auction?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    Payment to sellers will be made within 30-45 days after the sale, provided that full payment has been received from the buyer.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <AccordionItemHeading>
                                    <AccordionItemButton>
                                    Can I request updates on the bidding activity for my violin?
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                <AccordionItemPanel>
                                    <p>
                                    During the auction, you can view your item on our website and track the progress of the sale in real-time. This feature allows you to stay informed about the bidding activity and ensures transparency throughout the auction process.
                                    </p>
                                </AccordionItemPanel>
                            </AccordionItem>
                        </Accordion>
                    </div>
                </div>
            </div>
            <Parallax
                bgImage={image4}
                strength={475}
                renderLayer={(percentage) => (
                    <div />
                )}
                >
                <div style={{ height: 175 }}>
                    
                </div>
            </Parallax>
            <FooterUniversalHelper />
            <div className="copyright">Copyright @ 2024</div>
            <div className="overlay" />
        </Fragment>
    );
} 

export default SellersHelper;
