import React, { Fragment, useState } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import { NotificationManager } from 'react-notifications';
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { authentication } from "../../../redux/actions/authentication/auth";
import { connect } from "react-redux";
import _ from "lodash";
import LoadingOverlay from 'react-loading-overlay';
import "./styles.css";

const LoginHelperComponent = ({ authentication, authData }) => {
    const navigate = useNavigate();

    const [ state, setState ] = useState({
        email: "",
        password: "",
        isLoading: false
    });

    const validateEmail = (email) => {
        return String(email).toLowerCase().match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const handleSubmissionLogin = (e) => {
        e.preventDefault();
        
        setState(prevState => ({ ...prevState, isLoading: true }))

        const { password, email } = state;

        if ((typeof email !== "undefined" && email.length > 0 && validateEmail(email)) && (typeof password !== "undefined" && password.length > 0)) {
            console.log("valid");

            const config = {
                email,
                password
            }

            axios.post(`${process.env.REACT_APP_BASE_URL}/login`, config).then((res) => {
                if (res.data.message === "Successfully authenticated!") {

                    const { user } = res.data;

                    authentication(user);

                    setState(prevState => ({ ...prevState, isLoading: false }))

                    if (_.has(user, "verficationCompleted") && user.verficationCompleted === false) {
                        setTimeout(() => {
                            navigate("/confirm-email");
                        }, 1750);
                    } else {
                        NotificationManager.success('Successfully authenticated/logged-in!', 'Successfully authenticated.', 3000);
    
                        setTimeout(() => {
                            navigate("/");
                        },  3000);
                    }
                } else {
                    console.log("err");

                    setState(prevState => ({ ...prevState, isLoading: false }))

                    NotificationManager.warning('Your credentials did not match, please try again.', 'Invalid credentials.', 3000);
                }
            })
        } else { 
            console.log("invalid.");

            setState(prevState => ({ ...prevState, isLoading: false }))
            
            NotificationManager.error('An error occurred while processing your credentials...', 'Error during authentication', 3000);
        }
    }

    return (
        <Fragment>
            <NavigationUniversalHelper />
            {state.isLoading === true ? <LoadingOverlay
                active={state.isLoading}
                spinner
                className={"customspinner"}
                text='Processing your request, please wait...'
            /> : null}
            <div className="login-content">
                <form id="login-form">
                    <h2>Login</h2>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="YOUR EMAIL"
                        required=""
                        value={state.email}
                        onChange={(e) => setState(prevState => ({ ...prevState, email: e.target.value }))}
                    />
                    <input
                        type="password"
                        id="password"
                        name="password"
                        placeholder="YOUR PASSWORD"
                        required=""
                        value={state.password}
                        onChange={(e) => setState(prevState => ({ ...prevState, password: e.target.value }))}
                    />
                    <div className="remember-forgot">
                        {/* <label>
                            <input type="checkbox" id="remember-me" name="remember-me" />
                            Remember Me
                        </label> */}
                        <Link to="/forgot-password" className="forgot-password">
                            Forgot Password?
                        </Link>
                    </div>
                    <div className="text-center">
                        <Link to="/register" style={{ fontWeight: "bold" }} className="forgot-password">
                            No account yet? Create one!
                        </Link>
                    </div>
                    {!_.has(authData, "uniqueId") ? <a onClick={handleSubmissionLogin}>
                        <p style={{ color: "#fff" }} className="btn">Login</p>
                    </a> : null}
                </form>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};
const mapStateToProps = (state) => {
    return {
        authData: typeof state.auth !== "undefined" && typeof state.auth.data !== "undefined" && _.has(state.auth.data, "uniqueId") ? state.auth.data : null
    }
};

export default connect(mapStateToProps, { authentication })(LoginHelperComponent);