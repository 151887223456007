import React, { Fragment, useEffect } from "react";
import ProfileSettingsHelper from "../../components/profile/main";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = await loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const ProfileSettingsPage = (props) => {

    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    
    return (
        <Fragment>
            <Elements stripe={stripePromise}>
                <ProfileSettingsHelper props={props} />
            </Elements>
        </Fragment>
    );
};

export default ProfileSettingsPage;