import React, { Fragment, useEffect, useState } from "react";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";
import "./styles.css";
import { connect } from "react-redux";
import { NotificationManager } from 'react-notifications';
import axios from "axios";
import ContentLoader from 'react-content-loader'
import {
    useWindowSize
} from '@react-hook/window-size'
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import uuid from "react-uuid";
import _ from "lodash";
import Downshift from 'downshift';


const currentDate = new Date();
// Array of month names in full format
const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
// Get the month number from the Date object (0 for January, 1 for February, etc.)
const monthNumber = currentDate.getMonth();
const fullYear = currentDate.getFullYear();
// Get the full month name by indexing into the monthNames array
const fullMonthName = monthNames[monthNumber];

const PERCENTAGE_FEE = 0.20;
const settings = {
    dots: false,
    infinite: true,
    speed: 2500,
    autoplaySpeed: 2500,
    autoplay: true,
    adaptiveHeight: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    ending: "newest",
    price: "low"
};     

const EntireCatalogMainHelper = ({ authData }) => {
    const navigate = useNavigate();
    const [width, height] = useWindowSize();
    
    const [ state, setState ] = useState({
        listings: []
    });

    useEffect(() => {
        const configuration = {
            ending: new Date(state.ending),
            price: state.price
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/live/current/auctions/filtered`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listings!") {
                console.log("res.data", res.data);

                const { listings } = res.data;

                setState(prevState => ({ ...prevState, listings }));

                // NotificationManager.success('Successfully fetched/gathered live auctions!', 'Successfully gathered listings.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }, []);;

    const findHighestBid = (arr) => {
        if (arr.length === 0) {
          return 0; // or handle the empty array case as needed
        }
        const highestBid = arr.reduce((maxBid, obj) => (obj.bidAmount > maxBid ? obj.bidAmount : maxBid), arr[0].bidAmount);
        return highestBid;
    }

    const addCommasToNumber = (number) => {
        // Convert the number to a string
        const numberString = number.toString();
        // Use a regular expression to add commas
        const formattedNumber = numberString.replace(/\B(?=(\d{3})+(?!\d))/g, ',');    
        return formattedNumber;
    };

    console.log("stateeeeeeeeeeeeeeeeeeee :", state);
    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="min-height">
                <div style={{ paddingTop: 50 }} className="container">
                    <div className="row">
                        <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6">
                            <h1 style={{ color: "rgba(128, 0, 32, 0.65)" }}>{fullMonthName}, {fullYear}</h1>
                        </div>
                        {typeof authData !== "undefined" && _.has(authData, "verficationCompleted") && authData.verficationCompleted === true ? null : <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12 col-xl-6">
                            <Link to="/register" className="btn btn-priamry" style={width <= 775 ? { color: "#fff" } : { color: "#fff", float: "right" }}>Register to bid!</Link>
                        </div>}
                        <Link to="/current_auc" className="btn btn-priamry" style={width <= 775 ? { color: "#fff", maxWidth: 325, maxHeight: 42.25, marginTop: 5, marginLeft: 10 } : { color: "#fff", float: "right", maxWidth: 325, maxHeight: 42.25, marginTop: 5 }}>Back to auctions</Link>
                    </div>
                    <div className="row">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12 card-margin">
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="row no-gutters">
                                                {/* <div className="col-lg-2 col-md-2 col-sm-12 p-0">
                                                    <select
                                                    className="form-control"
                                                    id="exampleFormControlSelect1"
                                                    >
                                                    <option>Location</option>
                                                    <option>London</option>
                                                    <option>Boston</option>
                                                    <option>Mumbai</option>
                                                    <option>New York</option>
                                                    <option>Toronto</option>
                                                    <option>Paris</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-2 col-md-2 col-sm-12 p-0">
                                                    <select
                                                    className="form-control"
                                                    id="exampleFormControlSelect1"
                                                    >
                                                    <option>Location</option>
                                                    <option>London</option>
                                                    <option>Boston</option>
                                                    <option>Mumbai</option>
                                                    <option>New York</option>
                                                    <option>Toronto</option>
                                                    <option>Paris</option>
                                                    </select> 
                                                </div> */}
                                                {/* <div className="col-lg-1 col-md-1 col-sm-12 p-0">
                                                    <div className="mx-auto centered" style={{ maxHeight: 20 }}>
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width={24}
                                                            height={24}
                                                            viewBox="0 0 24 24"
                                                            fill="none"
                                                            stroke="currentColor"
                                                            strokeWidth={2}
                                                            strokeLinecap="round"
                                                            strokeLinejoin="round"
                                                            className="feather feather-search"
                                                        >
                                                            <circle cx={11} cy={11} r={8} />
                                                            <line x1={21} y1={21} x2="16.65" y2="16.65" />
                                                        </svg>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-12 col-md-12 col-sm-12 p-0">
                                    <Downshift
                                        onChange={selection => {
                                            navigate(`/lot_page/${selection.id}`);
                                        }}
                                        itemToString={item => (item ? item.title : '')}
                                    >
                                        {({
                                            getInputProps,
                                            getItemProps,
                                            getLabelProps,
                                            getMenuProps,
                                            isOpen,
                                            inputValue,
                                            highlightedIndex,
                                            selectedItem,
                                            getRootProps
                                        }) => (
                                            <Fragment>
                                                <div
                                                    style={{display: 'inline-block', minWidth: "100%", width: "100%"}}
                                                    {...getRootProps({}, {suppressRefError: true})}
                                                >
                                                    <input style={{ minWidth: "100%", width: "100%" }} className="specialsearchinput form-control" type="text" placeholder="SEARCH CATALOG " {...getInputProps()} />
                                                </div>
                                                <ul {...getMenuProps()}>
                                                    {isOpen ? state.listings.filter(item => !inputValue || item.title.toLowerCase().includes(inputValue.toLowerCase())).map((item, index) => (
                                                        <li
                                                            {...getItemProps({
                                                                key: item.title,
                                                                index,
                                                                item,
                                                                style: {
                                                                    backgroundColor:
                                                                    highlightedIndex === index ? 'lightgray' : 'white',
                                                                    fontWeight: selectedItem === item ? 'bold' : 'normal',
                                                                },
                                                            })}
                                                            className="list-styled-item"
                                                        >
                                                            {item.title}
                                                        </li>
                                                    )) : null}
                                                </ul>
                                            </Fragment>
                                        )}
                                    </Downshift>
                                </div>
                                <div className="col-12">
                                    <div className="card card-margin">
                                        <div className="card-body">
                                            <div className="row search-body">
                                                <div className="col-lg-12">
                                                <div className="search-result">
                                                    <div className="result-header">
                                                    <div className="row">
                                                        <div className="col-lg-6">
                                                        <div className="records">
                                                            Showing: <b>1-{typeof state.listings !== "undefined" && state.listings.length > 0 ? state.listings.length : 0}</b> of <b>{typeof state.listings !== "undefined" && state.listings.length > 0 ? state.listings.length : 0}</b> result(s)
                                                        </div>
                                                        </div>
                                                        <div className="col-lg-6">
                                                        <div className="result-actions">
                                                            <div className="result-sorting">
                                                                <span>Sort By:</span>
                                                                {/* <select
                                                                    className="form-control border-0"
                                                                    id="exampleOption"
                                                                >
                                                                    <option value={1}>Relevance</option>
                                                                    <option value={2}>Names (A-Z)</option>
                                                                    <option value={3}>Names (Z-A)</option>
                                                                </select> */}
                                                                {/* <select
                                                                    className="form-control border-0"
                                                                    id="exampleOption"
                                                                    onChange={(e) => setState(prevState => {
                                                                        return { ...prevState, ending: e.target.value, price: "" };
                                                                    })}
                                                                >
                                                                    <option>Date Ending</option>
                                                                    <option value={"newest"}>Newest</option>
                                                                    <option value={"oldest"}>Oldest</option>
                                                                </select> */}
                                                                <select
                                                                    className="form-control border-0"
                                                                    id="exampleOption"
                                                                    onChange={(e) => setState(prevState => {
                                                                        return { ...prevState, price: e.target.value, ending: "" };
                                                                    })}
                                                                >
                                                                    <option value={1}>Price H/L</option>
                                                                    <option value={"low"}>Low</option>
                                                                    <option value={"high"}>High</option>
                                                                </select>
                                                            </div>
                                                        </div>
                                                        </div>
                                                    </div>
                                                    </div>
                                                    <div className="result-body">
                                                    <div className="table-responsive">
                                                        <table className="table widget-26">
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <p className="widget-26-job-salary" style={{ fontWeight: "bold" }}>Listing:</p>
                                                                </td>
                                                                <td>
                                                                    <p className="widget-26-job-salary" style={{ fontWeight: "bold" }}>Title:</p>
                                                                </td>
                                                                <td>
                                                                    <div className="widget-26-job-salary" style={{ fontWeight: "bold" }}># Of Bids:</div>
                                                                </td>
                                                                <td>
                                                                    <div className="widget-26-job-salary" style={{ fontWeight: "bold" }}>Amount:</div>
                                                                </td>
                                                                <td>
                                                                    <div className="widget-26-job-salary" style={{ fontWeight: "bold" }}>Ends in:</div>
                                                                </td>
                                                            </tr>
                                                            {typeof state.listings !== "undefined" && state.listings.length > 0 ? state.listings.sort((a, b) => {
                                                                const aaa = findHighestBid(a.bidsArray);
                                                                const bbb = findHighestBid(b.bidsArray);

                                                                if (state.price === "low") {
                                                                    return aaa - bbb;
                                                                } else {
                                                                    return bbb - aaa;
                                                                }
                                                            }).map((listing, index) => {
                                                                const highestBidAmount = addCommasToNumber(findHighestBid(listing.bidsArray));
                                                                const highestBidAmountNum = findHighestBid(listing.bidsArray);
                                                                let now = moment(new Date());
                                                                let end = moment(new Date(listing.endDate));
                                                                let duration = moment.duration(end.diff(now));
                                                                let months = duration.months();
                                                                let days = duration.days();
                                                                let hours = duration.hours();
                                                                let minutes = duration.minutes();
                                                                let formattedDuration = `${days}d ${hours}h ${minutes}m`;
                                                                return (
                                                                    <Fragment key={index}>
                                                                        <tr onClick={() => {
                                                                            navigate(`/lot_page/${listing.id}`);
                                                                        }} className="hover">
                                                                            <td>
                                                                                <div className="widget-26-job-emp-img">
                                                                                    <p>Lot #: {listing.id.split("-")[0]}</p>
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div style={Math.floor(Number(listing.reserveBidPrice)) <= Math.floor(Number(findHighestBid(listing.bidsArray))) ? { color: "green" } : { color: "red" }} className="widget-26-job-title">
                                                                                    {listing.title}
                                                                                </div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="widget-26-job-salary">{listing.bidsArray.length} bid(s)</div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="widget-26-job-salary">${highestBidAmountNum > 0 ? highestBidAmount : listing.bidPrice}</div>
                                                                            </td>
                                                                            <td>
                                                                                <div className="widget-26-job-salary">{formattedDuration}</div>
                                                                            </td>
                                                                        </tr>
                                                                    </Fragment>
                                                                );
                                                            }) : <div style={{ margin: 32.25 }}>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                    <div className="col-md-3 col-lg-3 col-sm-12">
                                                                        <ContentLoader />
                                                                    </div>
                                                                </div>
                                                            </div>}
                                                        </tbody>
                                                        </table>
                                                    </div>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
        authData: state.auth.data
    }
};

export default connect(mapStateToProps, { })(EntireCatalogMainHelper);