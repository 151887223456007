import React, { Fragment } from "react";
import "./styles.css";
import NavigationUniversalHelper from "../../universal/navigation/index";
import FooterUniversalHelper from "../../universal/footer/index";

const ShippingLabelsHelper = (props) => {
    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="min-height">
                <p>ShippingLabelsHelper helper.</p>
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
} 
export default ShippingLabelsHelper;