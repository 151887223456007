import React, { useEffect } from "react";
import EntireCatalogMainHelper from "../../../components/auction/entire/index";

const EntireCatalogMainPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return <EntireCatalogMainHelper props={props} />
};

export default EntireCatalogMainPage;