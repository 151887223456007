import React, { Fragment, useEffect } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Parallax, Background } from "react-parallax";
import image4 from "../../assets/bar.jpg"
import notes from "../../assets/notes.jpg"
const markdown = `1\ . **Introduction**

Welcome to Chicago Violin Auction LLC's Privacy Policy. We are dedicated to safeguarding your privacy and ensuring the security of your personal information. This Privacy Policy delineates our practices regarding the collection, utilization, and protection of your data in accordance with our Terms and Conditions of Use Agreement.

2\ . **Information Collected**

We may gather the following types of information:

Personal Information: This encompasses details such as your name, address, contact information, and government-issued photo ID. We collect this data during registration to authenticate your identity.

Financial Information: We collect credit card details for both verification and payment purposes, ensuring secure transactions.

Additional Information: At our discretion, we may request supplementary information or documents to facilitate your engagement with our services.

Correspondence: Any communications exchanged between you and us, whether in person, via email, mail, phone, or other channels, may be recorded and stored. This includes, but is not limited to, photographs, measurements, voice recordings, memos, and text-based messages.

Log Data: Whenever you visit our Service, we collect information that your browser sends to us that is called Log Data. This Log Data may include information such as your computer's Internet Protocol ("IP") address, browser version, pages of our Service that you visit, the time and date of your visit, the time spent on those pages, and other statistics.

3\ . **Use of Information**

We utilize the collected information for the following purposes:

Identity Verification: Verifying your identity and eligibility to access our services.

Auction Facilitation: Facilitating the bidding and purchasing process during our auctions.

Communication: Communicating with you regarding auction-related matters, such as announcements, payments, and inquiries.

Customer Support: Providing assistance and support to address any concerns or queries you may have.

Security: Ensuring the security and integrity of our services, including the prevention of unauthorized access or fraudulent activities

4\ . **Security**

We value your trust in providing us your Personal Information, thus we are striving to use commercially acceptable means of protecting it. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee its absolute security.

5\ . **Cookies**

Cookies are files with small amount of data that is commonly used an anonymous unique identifier. These are sent to your browser from the website that you visit and are stored on your computer's hard drive.

Our website uses these "cookies" to collection information and to improve our Service. You have the option to either accept or refuse these cookies, and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some portions of our Service.

6\ . **Links to Other Sites**

Our Service may contain links to other sites. If you click on a third-party link, you will be directed to that site. Note that these external sites are not operated by us. Therefore, we strongly advise you to review the Privacy Policy of these websites. We have no control over, and assume no responsibility for the content, privacy policies, or practices of any third-party sites or services.

7\ . **Children's Privacy**

Our Services do not address anyone under the age of 18. We do not knowingly collect personal identifiable information from children under 18. In the case we discover that a child under 18 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, please contact us so that we will be able to do necessary actions.

8\ . **Changes to this Privacy Policy**

We may update our Privacy Policy from time to time. Thus, we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.

9\ . **Contact Us**

If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us.`;


const PrivacyPolicyPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="middle-content-backgrounded">
                <div className="container" style={{ backgroundColor: "#fff" }}>
                    <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                        <Markdown remarkPlugins={[remarkGfm]}>{markdown}</Markdown>
                    </div>
                </div>
            </div>
            <Parallax
                bgImage={image4}
                strength={475}
                renderLayer={(percentage) => (
                    <div />
                )}
                >
                <div style={{ height: 175 }}>
                    
                </div>
            </Parallax>
            <FooterUniversalHelper />
            <div className="copyright">Copyright @ 2024</div>
            <div className="overlay" />
        </Fragment>
    );
} 

export default PrivacyPolicyPage;