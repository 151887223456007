import React, { Fragment, useEffect } from "react";
import TermsAndConditionsHelper from "../../components/termsConditions/index";

const TermsAndConditionsPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return (
        <Fragment>
            <TermsAndConditionsHelper props={props} />
        </Fragment>
    );
};

export default TermsAndConditionsPage;