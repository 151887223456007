import React, { Fragment, useEffect } from "react";
import NavigationUniversalHelper from "../universal/navigation/index";
import FooterUniversalHelper from "../universal/footer/index";
import "./styles.css";
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { Parallax, Background } from "react-parallax";
import image4 from "../../assets/bar.jpg"
import notes from "../../assets/notes.jpg"

const markdown = `1\ . **Introduction**

Chicago Violin Auction LLC ("we" or "us") utilizes cookies and similar tracking technologies on our website chicagoviolinauction.com (the "website"). This Cookies Policy outlines our use of cookies, how we utilize them, and your choices regarding their usage.

2\ . **What are Cookies?**

Cookies are small text files that are placed on your device when you visit a website. They serve various purposes, such as enabling website functionality, enhancing user experience, and providing analytical insights.

3\ . **Types of Cookies We Use**

We utilize the following types of cookies on our website:

- Essential Cookies: These cookies are necessary for the operation of our website. They enable basic functions such as page navigation and access to secure areas of the website. Without these cookies, the website may not function properly.

-  Analytical/Performance Cookies: These cookies allow us to analyze how visitors interact with our website, including which pages are visited most frequently and how users navigate the site. This information helps us improve the functionality and performance of our website.

- Functionality Cookies: Functionality cookies enable enhanced features and personalization, such as remembering your preferences and settings. These cookies enhance your browsing experience by providing customized content and features.

- Marketing/Targeting Cookies: These cookies are used to deliver personalized advertisements and promotional content based on your interests and browsing behavior. They may also be used to measure the effectiveness of advertising campaigns.

4\ . **Third-Party Cookies**

We may also allow third-party service providers, such as analytics providers and advertisers, to place cookies on your device. These third-party cookies enable us to access analytics data and deliver targeted advertisements. However, we do not have control over these cookies or the data collected by them. Please refer to the respective privacy policies of these third-party providers for more information on their data practices.

5\ . **Cookie Consent**

By using our website, you consent to the placement of cookies on your device as described in this Cookies Policy. You can manage your cookie preferences and consent settings through your browser settings. Please note that disabling certain types of cookies may impact the functionality of our website.

6\ . **How to Manage Cookies**

You can manage your cookie preferences and settings through your browser settings. Most web browsers allow you to control cookies through their settings or preferences menus. You can typically set your browser to block or delete cookies, or to alert you when cookies are being used. However, please note that blocking or deleting cookies may affect your ability to access certain features and functionality of our website.

7\ . **Updates to this Policy**

We may update this Cookies Policy from time to time to reflect changes in our practices or applicable laws. We encourage you to review this policy periodically for any updates or changes.

8\ . **Contact Us**

If you have any questions or concerns about our use of cookies or this Cookies Policy, please contact us.

This Cookies Policy was last updated on 2/7/2024.`;

const CookiePolicyPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return (
        <Fragment>
            <NavigationUniversalHelper />
            <div className="middle-content-backgrounded">
                <div className="container" style={{ backgroundColor: "#fff" }}>
                    <div style={{ paddingTop: 50, paddingBottom: 50 }}>
                        <Markdown remarkPlugins={[remarkGfm]}>{markdown}</Markdown>
                    </div>
                </div>
            </div>
            <Parallax
                bgImage={image4}
                strength={475}
                renderLayer={(percentage) => (
                    <div />
                )}
                >
                <div style={{ height: 175 }}>
                    
                </div>
            </Parallax>
            <FooterUniversalHelper />
            <div className="copyright">Copyright @ 2024</div>
            <div className="overlay" />
        </Fragment>
    );
} 

export default CookiePolicyPage;