import React, { Fragment, useEffect, useState } from "react";
import "./styles.css";
import NavigationUniversalHelper from "../../../universal/navigation/index";
import FooterUniversalHelper from "../../../universal/footer/index";
import { Link, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import _ from "lodash";
import { NotificationManager } from 'react-notifications';
import ContentLoader from 'react-content-loader'
import moment from "moment";
import { Modal } from 'react-responsive-modal';

const ManageListingAuctionEndedHelper = (props) => {
    const params = useParams();
    const { id } = params;
    const navigate = useNavigate();
    const [ state, setState ] = useState({
        listing: null,
        user: null,
        showDialog: false,
        weightOfPackage: "",
        page: 1,
        selectedPayment: null,
        validatedAddress: null,
        dimensions: {
            packageHeight: "",
            packageLength: "",
            packageWidth: ""
        },
        shipping: null
    })

    useEffect(() => {
        const configuration = {
            params: {
                uniqueId: id
            }
        }

        axios.get(`${process.env.REACT_APP_BASE_URL}/fetch/listing/individual`, configuration).then((res) => {
            if (res.data.message === "Successfully gathered listing!") {
                console.log("res.data", res.data);

                const { listing, user } = res.data;

                setState(prevState => ({ ...prevState, listing, shipping: _.has(listing, "shipping") ? listing.shipping : null, user }));

                // NotificationManager.success('Successfully fetched/gathered ended listing!', 'Successfully gathered listing.', 3000);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    }, [])
    
    console.log("params", params, state);

    const markAsShipped = () => {
        console.log("mark as shipped clicked/ran...");

        const { 
            weightOfPackage,
            dimensions,
            validatedAddress
        } = state;

        if ((typeof dimensions.packageHeight !== "undefined" && dimensions.packageHeight.length > 0) && (typeof dimensions.packageLength !== "undefined" && dimensions.packageLength.length > 0) && (typeof dimensions.packageWidth !== "undefined" && dimensions.packageWidth.length > 0) && (typeof weightOfPackage !== "undefined" && weightOfPackage.length > 0)) {
            const config = {
                id,
                weightOfPackage,
                dimensions,
                validatedAddress
            };
    
            axios.post(`${process.env.REACT_APP_BASE_URL}/mark/as/shipped/notify`, config).then((res) => {
                if (res.data.message === "Successfully marked as shipped!") {
                    console.log("res.data", res.data);

                    const { shipping } = res.data;
                    
                    if (typeof shipping.errors !== "undefined" && shipping.errors.length > 0) {
                        NotificationManager.warning("A technical error with the shipping provider occurred...", "UNKOWN TECHNICAL ERROR W/SHIPPING.", 3250);
                    } else {
                        setState(prevState => ({ ...prevState, shipping: JSON.parse(shipping), showDialog: false, weightOfPackage: "",
                            validatedAddress: null,
                            dimensions: {
                                packageHeight: "",
                                packageLength: "",
                                packageWidth: ""
                            }
                        }))
        
                        NotificationManager.success("Successfully marked as shipped and notified the user of this action.", "Successfully marked as shipped!", 3250);
                    }
                } else {
                    console.log("err");
    
                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })
        } else {
            NotificationManager.warning('Fill out all the fields before proceeding.', 'Fill out every field appropriately.', 3000);
        }
    };

    const refundPayment = () => {
        console.log("refundPayment clicked/ran...");

        const config = {
            paymentIntentId: state.selectedPayment.paymentID
        };

        axios.post(`${process.env.REACT_APP_BASE_URL}/refund/user/payment/exchange`, config).then((res) => {
            if (res.data.message === "Successfully ran logic!") {
                console.log("res.data", res.data);

                setState(prevState => ({ ...prevState, selectedPayment: null }));
                
                NotificationManager.success("Successfully refunded this users payment and cancelled the transaction.", "Successfully refunded.", 3250);
            } else {
                console.log("err");

                NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
            }
        })
    };

    const findHighestBid = (arr) => {
        if (arr.length === 0) {
          return 0; // or handle the empty array case as needed
        }
        const highestBid = arr.reduce((maxBid, obj) => (obj.bidAmount > maxBid ? obj.bidAmount : maxBid), arr[0].bidAmount);
        return highestBid;
    }

    const renderShippingContent = () => {
        console.log("state.shipping", state.shipping);

        const { shipping } = state;

        if (shipping !== null && !_.has(shipping, "errors")) {
            return (
                <Fragment>
                    <p className="text-left" style={{ textAlign: "left", lineHeight: 0.5, marginTop: 17.25 }}>The shipping cost is: ${typeof shipping !== "undefined" && typeof shipping.shipment_cost !== "undefined" && typeof shipping.shipment_cost.amount !== "undefined" ? shipping.shipment_cost.amount : 0} ({shipping.shipment_cost.currency.toUpperCase()})</p>
                    <p className="text-left" style={{ textAlign: "left", lineHeight: 0.5 }}>Service Type/Code: {shipping.service_code}</p>
                    <p className="text-left" style={{ textAlign: "left", lineHeight: 0.5 }}>Tracking number: {shipping.tracking_number}</p>
                    <p className="text-left" style={{ textAlign: "left", lineHeight: 0.5 }}>Carrier code: <b style={{ textDecorationLine: "underline" }}>{shipping.carrier_code.toUpperCase()}</b></p>
                    <p className="text-left" style={{ textAlign: "left", lineHeight: 0.5 }}>Voided: {shipping.voided === true ? "Voided." : "Not Voided."}</p>
                    <a target="_blank" href={shipping.label_download.png} download={shipping.label_download.png}>
                        <img src={shipping.label_download.png} style={{ maxWidth: "50%" }} />
                    </a>
                </Fragment>
            );
        } 
    };

    const renderPages = (user, listing) => {
        console.log("listing.payments", listing.payments);
        if (state.page === 1) {
            return (
                <Fragment>
                    <div className="tab-content">
                        {/* <div className="tab-pane"> */}
                        <div className="tab-body">
                            <div className="section-title-wr">
                            <h3 className="section-title left">
                                Winning user information & location
                            </h3>
                            </div>
                            <p className="text-left" style={{ textAlign: "left" }}>
                                <hr />
                                <b>Shipping address / user address</b>: {_.has(user, "selectedAddress") ? user.selectedAddress.formatted_address : "No address provided."}<br />
                                <hr />
                                <b>Shipping label and information (will be propagated once marked as "shipped")</b>
                                {renderShippingContent()}
                            </p>
                            <div className="section-title-wr">
                            <h3 className="section-title left">Additional details</h3>
                            </div>
                            <table className="table table-bordered table-striped table-hover table-responsive hideonmobile">
                            <tbody>
                                <tr>
                                <td>
                                <b>Winning user name</b>: {user.firstName} {user.lastName} <br />
                                </td>
                                <td>
                                <b>Winning user username</b>: {user.username}<br />
                                </td>
                                <td>
                                <b>Winning DOB</b>: {moment(user.dateOfBirth).format("MM/DD/YYYY")}<br />
                                </td>
                                </tr>
                                <tr>
                                <td>
                                <b>Winning user email</b>: {user.email}<br />
                                </td>
                                <td>
                                <b>Winning user identifier</b>: {user.uniqueId}<br />
                                </td>
                                <td>
                                <b>Winning user bidder ID</b>: {user.bidderID}<br />
                                </td>
                                </tr>
                                <tr>
                                <td>
                                <b>Secondary Email</b>: {user.secondaryEmail}<br />
                                </td>
                                <td>
                                <b>Subscribed to notifications</b>: {user.enrolledEmailNotifications === true ? "Subscribed!" : "Not subscribed."}<br />
                                </td>
                                <td>
                                <b>Total Loggin's</b>: {_.has(user, "securityLogs") ? user.securityLogs.length : 1}<br />
                                </td>
                                </tr>
                                <tr>
                                <td>
                                <b>Registered</b>: {user.registrationDateString}<br />
                                </td>
                                <td>
                                <b>Phone Number</b>: {user.phoneNumber}<br />
                                </td>
                                <td />
                                </tr>
                            </tbody>
                            </table>
                            <p className="hideondesktop" style={{ color: "red" }}>View on desktop for full admin view/experience</p>
                            </div>
                        </div>
                </Fragment>
            );
        } else if (state.page === 2) {
            return (
                <Fragment>
                    <div className="tab-content">
                        {/* <div className="tab-pane"> */}
                        <div className="tab-body">
                            <div className="section-title-wr">
                            <h3 style={{ marginBottom: 15 }} className="section-title left">
                                Payments from this auction winner
                            </h3>
                            </div>
                            <div class="result-body">
                                    <div class="table-responsive">
                                        <table class="table widget-26">
                                            <tbody>
                                                {typeof listing.payments !== "undefined" && listing.payments.length > 0 ? listing.payments.map((payment, index) => {
                                                    return (
                                                        <Fragment key={index}>
                                                            <tr>
                                                                <td>
                                                                    <div class="widget-26-job-emp-img">
                                                                        <img src="https://bootdey.com/img/Content/avatar/avatar5.png" alt="Company" />
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="widget-26-job-title">
                                                                        <a href="#">Payment ID: {payment.paymentID}</a>
                                                                        <p class="m-0"><a href="#" class="employer-name">Payment made approx.</a> <span class="text-muted time">{moment(new Date(payment.date)).fromNow(false)}</span></p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="widget-26-job-info">
                                                                        <p class="type m-0">This is a completed payment</p>
                                                                        <p class="text-muted m-0">in <span class="location">Confirmed/Collected</span></p>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div class="widget-26-job-salary">${payment.paymentAmount}</div>
                                                                </td>
                                                                <td>
                                                                    <div class="widget-26-job-category bg-soft-success">
                                                                        <i class="indicator bg-success"></i>
                                                                        <span>Successful Payment</span>
                                                                    </div>
                                                                </td>
                                                                <td style={state.selectedPayment !== null && state.selectedPayment.paymentID === payment.paymentID ? { backgroundColor: "pink" } : {}}>
                                                                    <div class="widget-26-job-starred">
                                                                        <a onClick={() => setState(prevState => ({ ...prevState, selectedPayment: payment }))}>
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                width="24"
                                                                                height="24"
                                                                                viewBox="0 0 24 24"
                                                                                fill="none"
                                                                                stroke="currentColor"
                                                                                stroke-width="2"
                                                                                stroke-linecap="round"
                                                                                stroke-linejoin="round"
                                                                                class="feather feather-star"
                                                                            >
                                                                                <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                                                                            </svg>
                                                                        </a>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </Fragment>
                                                    );
                                                }) : null}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                </Fragment>
            );
        }
    }

    const renderContent = () => {
        const { listing, user } = state;

        // console.log("listing.payments", listing.payments);

        if (listing !== null && user !== null) {
            const highestBidAmount = findHighestBid(listing.bidsArray);
            return (
                <Fragment>
                    <div style={{ paddingTop: 75 }} className="estate">
                        <div className="container bootstrap snippets bootdey">
                            <div className="row">
                            <div className="col-md-12">
                                <div className="row">
                                <div className="col-md-7">
                                    <div className="product-gallery">
                                    <div className="primary-image">
                                        <a href="#" className="theater" rel="group" hidefocus="true">
                                        <img
                                            src={listing.images[0].location}
                                            className="img-responsive"
                                            style={{ objectFit: "contain" }}
                                            alt=""
                                        />
                                        </a>
                                    </div>
                                    <div className="thumbnail-images">
                                        <a href="#" className="theater" rel="group" hidefocus="true">
                                        <img
                                            src={_.has(listing.images[1], "location") && listing.images[1].location !== "undefined" && listing.images[1].location !== null ? listing.images[1].location : "https://www.bootdey.com/image/500x300/20B2AA/000000"}
                                            alt=""
                                        />
                                        </a>
                                        <a href="#" className="theater" rel="group" hidefocus="true">
                                        <img
                                            src={_.has(listing.images[2], "location") && listing.images[2].location !== "undefined" && listing.images[2].location !== null ? listing.images[2].location : "https://www.bootdey.com/image/500x300/FFA07A/000000"}
                                            alt=""
                                        />
                                        </a>
                                        <a href="#" className="theater" rel="group" hidefocus="true">
                                        <img
                                            src={_.has(listing.images[3], "location") && listing.images[3].location !== "undefined" && listing.images[3].location !== null ? listing.images[3].location : "https://www.bootdey.com/image/500x300/7B68EE/000000"}
                                            alt=""
                                        />
                                        </a>
                                        <a href="#" className="theater" rel="group" hidefocus="true">
                                        <img
                                            src={_.has(listing.images[4], "location") && listing.images[4].location !== "undefined" && listing.images[4].location !== null ? state.listing.images[4].location : "https://www.bootdey.com/image/500x300/C71585/000000"}
                                            alt=""
                                        />
                                        </a>
                                    </div>
                                    </div>
                                </div>
                                <div className="col-md-5">
                                    <div className="product-info">
                                    <h3>{listing.title}</h3>
                                    <div className="wp-block property list no-border">
                                        <div className="wp-block-content clearfix">
                                        <small>{`Ended about ${moment(listing.endDate).fromNow(false)}`}</small>
                                        <h4 className="content-title" style={{ marginTop: 12.5 }}>{listing.lotType}</h4>
                                        <p className="description mb-15 text-left" style={{ textAlign: "left" }}>
                                            {listing.itemDescription}
                                        </p>
                                        <span className="pull-left">
                                            <span className="price">${highestBidAmount}</span>
                                            <span className="period">final sale value</span>
                                        </span>
                                        <span className="pull-right">
                                            <span className="capacity">
                                            <i className="fa fa-user" />
                                            <i className="fa fa-user" />
                                            </span>
                                        </span>
                                        </div>
                                        <div className="wp-block-footer style2 mt-15">
                                        <ul className="aux-info">
                                            <li>
                                            <i className="fa fa-building" />
                                                {`${listing.bidsArray.length} total bid(s)`}
                                            </li>
                                            <li>
                                            <i className="fa fa-user" /> {listing.listingViews.length} listing views
                                            </li>
                                        </ul>
                                        </div>
                                    </div>
                                    <span className="clearfix" />
                                    <button className="btn btn-primary" onClick={() => {
                                        setState(prevState => ({ ...prevState, showDialog: true }))
                                    }}>Mark as shipped!</button>
                                    <hr />
                                    {state.selectedPayment !== null ? <button style={{ marginBottom: 30 }} className="btn btn-primary" onClick={() => refundPayment()}>Refund payment/cancel order</button> : null}
                                    </div>
                                </div>
                                </div>
                                {/* PROPERTY DESCRIPTION */}
                                <div style={{ paddingBottom: 75 }} className="row">
                                <div className="col-md-12">
                                    <div className="tabs-framed boxed">
                                    <ul className="tabs clearfix">
                                        <li className="active hover">
                                            <a onClick={() => setState(prevState => ({ ...prevState, page: 1 }))} data-toggle="tab">
                                                User details & more...
                                            </a>
                                        </li>
                                        <li className="active hover">
                                            <a onClick={() => setState(prevState => ({ ...prevState, page: 2 }))} data-toggle="tab">
                                                Payments from purchaser
                                            </a>
                                        </li>
                                        {/* <li>
                                        <a href="#tab-map" data-toggle="tab" data-type="gmap">
                                            Map
                                        </a>
                                        </li> */}
                                    </ul>
                                        {renderPages(user, listing)}    
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        } else {
            return (
                <Fragment>
                    <div style={{ margin: 32.25 }}>
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                            <div className="col-md-3 col-lg-3 col-sm-12">
                                <ContentLoader />
                            </div>
                        </div>
                    </div>
                </Fragment>
            );
        }
    }

    const validatePackageAddress = () => {
        console.log("validatePackageAddress clicked/ran...");

        const configuration = {
            params: {
                uniqueId: id
            }
        }
        const { user } = state;

        if (_.has(user, "selectedAddress")) {
            const query = user.selectedAddress.formatted_address;
            const API_Key = process.env.REACT_APP_TOMTOM_API_KEY;
    
            axios.get(`https://api.tomtom.com/search/2/geocode/${query}.json?key=${API_Key}`, configuration).then((res) => {
                if (res.data) {
                    console.log("res.data", res.data);
    
                    const { results } = res.data;
    
                    setState(prevState => ({ ...prevState, validatedAddress: results[0] }))
    
                    NotificationManager.success('Successfully validated delivery address!', 'Successfully validated.', 3000);
                } else {
                    console.log("err");
    
                    NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
                }
            })
        } else {
            NotificationManager.warning('An error occurred while processing desired request.', 'Error with request.', 3000);
        }
    };

    return (
        <Fragment>
            <NavigationUniversalHelper />
            {state.showDialog === true ? <Modal classNames="modalstretch" open={state.showDialog} onClose={() => setState(prevState => ({ ...prevState, showDialog: false }))} center>
                <div>
                    <p className="text-left">You'll need to complete the required shipping criteria before marking as shipped.</p>
                    <hr />
                    <p className="text-left">We'll need to verify the senders address before inputing the desired package weight and dimensions</p>
                    <div className="center centered">
                        <button style={{ width: "45%", marginTop: 12.5 }} onClick={() => validatePackageAddress()} className="btn btn-primary">Validate Delivery Address</button>
                    </div>
                    <hr />
                    {typeof state.validatedAddress !== "undefined" && state.validatedAddress !== null ? <Fragment>
                        <div className="col-md-12 col-lg-12 col-sm-12 col-xl-12">
                            <div className="row">
                                <div className="col-md-12 col-lg-12 col-sm-12">
                                    <label>Weight of your package</label>
                                    <input
                                        style={{ width: "100%" }}
                                        type="number"
                                        id="text"
                                        className="form-control spacing-input"
                                        name="text"
                                        autoComplete="off"
                                        placeholder="WEIGHT OF PACKAGE"
                                        tabIndex="8"
                                        required=""
                                        value={state.weightOfPackage}
                                        onChange={(e) => setState(prevState => ({ ...prevState, weightOfPackage: e.target.value }))}
                                    />
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col-md-4 col-lg-4 col-sm-12">
                                    <label>Height of package</label>
                                    <input
                                        style={{ width: "100%" }}
                                        type="number"
                                        id="text"
                                        className="form-control spacing-input"
                                        name="text"
                                        autoComplete="off"
                                        placeholder="HEIGHT OF PACKAGE (INCHES)"
                                        tabIndex="8"
                                        required=""
                                        value={state.dimensions.packageHeight}
                                        onChange={(e) => setState(prevState => ({ ...prevState, dimensions: {
                                            ...prevState.dimensions,
                                            packageHeight: e.target.value
                                        }}))}
                                    />
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12">
                                    <label>Width of your package</label>
                                    <input
                                        style={{ width: "100%" }}
                                        type="number"
                                        id="text"
                                        className="form-control spacing-input"
                                        name="text"
                                        autoComplete="off"
                                        placeholder="WIDTH OF PACKAGE (INCHES)"
                                        tabIndex="9"
                                        required=""
                                        value={state.dimensions.packageWidth}
                                        onChange={(e) => setState(prevState => ({ ...prevState, dimensions: {
                                            ...prevState.dimensions,
                                            packageWidth: e.target.value
                                        }}))}
                                    />
                                </div>
                                <div className="col-md-4 col-lg-4 col-sm-12">
                                    <label>Length of your package</label>
                                    <input
                                        style={{ width: "100%" }}
                                        type="number"
                                        id="text"
                                        className="form-control spacing-input"
                                        name="text"
                                        autoComplete="off"
                                        tabIndex="10"
                                        placeholder="LENGTH OF PACKAGE (INCHES)"
                                        required=""
                                        value={state.dimensions.packageLength}
                                        onChange={(e) => setState(prevState => ({ ...prevState, dimensions: {
                                            ...prevState.dimensions,
                                            packageLength: e.target.value
                                        }}))}
                                    />
                                </div>
                            </div>
                        </div>
                        <hr />
                        <button style={{ width: "100%", marginTop: 12.5 }} onClick={() => markAsShipped()} className="btn btn-primary">Submit Package Details & Ship/Notify</button>
                    </Fragment> : null}
                </div>
            </Modal> : null}
            <div className="min-height">
                {renderContent()}
            </div>
            <FooterUniversalHelper />
        </Fragment>
    );
}
export default ManageListingAuctionEndedHelper;
