import React, { useEffect } from "react";
import IndividualAuctionViewHelper from "../../../components/auction/individual/index";

const AuctionIndividualPage = (props) => {
    useEffect(() => {
        window.scrollTo(0, 0); //
    }, []);
    return <IndividualAuctionViewHelper props={props} />
};

export default AuctionIndividualPage;