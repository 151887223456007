import React, { Fragment } from "react";
import SellersHelper from "../../components/sellers/index";

const SellersPage = (props) => {
    return (
        <Fragment>
            <SellersHelper props={props} />
        </Fragment>
    );
};

export default SellersPage;